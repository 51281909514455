<template>
    <Layout>
        <PageHeader :title="title" :items="items"/>
        <loading v-if="loader"/>
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <h4 class="card-title">All Employers</h4>
                        <p class="card-title-desc"></p>
                        <div class="row mb-md-2">
                            <div class="col-sm-12 col-md-6">
                                <div id="tickets-table_length" class="dataTables_length">
                                </div>
                            </div>
                            <!-- Search -->
                            <div class="col-sm-12 col-md-6">
                                <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                                    <label class="d-inline-flex align-items-center">
                                        Search:
                                        <b-form-input
                                                v-model="filter"
                                                type="search"
                                                placeholder="Search..."
                                                class="form-control form-control-sm ml-2"
                                        ></b-form-input>
                                    </label>
                                </div>
                            </div>
                            <!-- End search -->
                        </div>
                        <!-- Table -->
                        <div class="table-responsive mb-0">
                            <b-table
                                    :items="employers"
                                    :fields="fields"
                                    responsive="sm"
                                    :per-page="perPage"
                                    :current-page="currentPage"
                                    :sort-by.sync="sortBy"
                                    :sort-desc.sync="sortDesc"
                                    :filter="filter"
                                    :filter-included-fields="filterOn"
                                    @filtered="onFiltered"
                            >
                                <template v-slot:cell(address)="employer">
                                    <div class="text-center address" :title="employer.item.location.street_address"> {{employer.item.location.street_address}} </div>
                                </template>
                                <template v-slot:cell(status)="employer">
                                    <button :class="employer.item.status === 'Block' ? btn_color1:btn_color2"
                                            @click="toggleState(employer)">
                                        <div v-if="employer.item.status === 'Block'">Block</div>
                                        <div v-else>Unblock</div>
                                    </button>&nbsp;

                                </template>
                                <template v-slot:cell(detail)="employer">
                                    <button class="btn btn-sm btn-outline-info" @click="details(employer)">Detail</button>
                                </template>
                                <template v-slot:cell(action)="employer">
                                    <button class="btn btn-danger btn-sm" @click="deleteEmployer(employer.item.id)"><i
                                            class="fas fa-trash-alt"></i>
                                    </button>&nbsp;

                                </template>

                                <!-- <template v-slot:cell(actions)="employer">
                                    <button class="btn btn-primary btn-sm" @click="employerDetails(employer)">Details
                                    </button>&nbsp;
                                </template> -->
                            </b-table>
                        </div>
                        <div class="row">
                            <div class="col">
                                <div class="dataTables_paginate paging_simple_numbers float-right">
                                    <ul class="pagination pagination-rounded mb-0">
                                        <!-- pagination -->
                                        <pagination :data="pagination" @pagination-change-page="getEmployers"
                                                    :limit="limit"></pagination>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
</template>

<script>
    import Layout from "../../layouts/main";
    import PageHeader from "@/components/page-header";
    import loading from "../animation/loading";
    import {employerMethods} from "@/state/helpers";
    import appConfig from "@/app.config";
    import pagination from "laravel-vue-pagination";

    /**
     * Advanced table component
     */
    export default {
        page: {
            title: "Employers",
            meta: [{name: "description", content: appConfig.description}]
        },
        components: {Layout, PageHeader, loading, pagination},
        data() {
            return {
                loader: false,
                limit: 5,
                title: "Employers",
                items: [
                    {
                        text: "Any",
                        href: "/"
                    },
                    {
                        text: "Employers",
                        href: "/"
                    }
                ],
                btn_color1: 'btn btn-success btn-sm',
                btn_color2: 'btn btn-danger btn-sm',
                totalRows: 1,
                currentPage: 1,
                perPage: 25,
                filter: null,
                filterOn: [],
                sortBy: "id",
                sortDesc: true,
                fields:
                    [
                        {key: "name", label: 'Name', sortable: true},
                        {key: "email", label: 'Email', sortable: true},
                        {key: "mobile_number", label: 'Phone', sortable: true},
                        {key: "location.city", label: 'City', sortable: true},
                        {key: "status", label: 'Status', sortable: false},
                        {key: "detail", label: 'Detail', sortable: false},
                        {key: "action", label: 'Action', sortable: false},
                        // {key: "actions"},
                    ]
            };
        },
        computed: {
            rows() {
                return this.$store.state.employer.employers.length;
            },
            employers() {
                return this.$store.state.employer.employers;
            },
            pagination() {
                return this.$store.state.employer.pagination
            }
        },
        watch: {
            currentPage: {
                handler: function () {
                }
            }
        },
        methods: {
            ...employerMethods,
            getEmployers(page = 1) {
                this.loader = true;
                this.getAllEmployers({page: page}).then(res => {
                    if (res.status === 200) {
                        this.loader = false;
                    }
                }).catch(() => {
                    this.loader = false;
                    this.$swal({
                        icon: 'error',
                        toast: true,
                        text: "Session Expired! Please login again.",
                        showCloseButton: false,
                        showConfirmButton: false,
                        timer: 3000
                    });
                });
            },
            details(employer){
                localStorage.setItem('userDetail',JSON.stringify(employer.item))
                this.$router.push({name:"employer_detail"})
            },
            onFiltered(filteredItems) {
                this.totalRows = filteredItems.length;
                this.currentPage = 1;
            },
            deleteEmployer(id){
                this.$swal({
                    icon: 'warning',
                    text: 'Are you sure to delete',
                    showCancelButton: true,
                    toast: true,
                    confirmButtonColor: 'green',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes',
                    cancelButtonText: 'No',
                }).then(result => {
                    if (result.value) {
                        this.msg = '';
                        this.deleteEmployerUser({id: id}).then(res => {
                            if (res.status === 200) {
                                this.msg = res.message ? res.message : "Employer deleted successfully"
                                this.$swal({
                                    icon: 'success',
                                    text: this.msg,
                                    toast: true,
                                    showCloseButton: false,
                                    showConfirmButton: false,
                                    timer: 3000
                                });
                            }
                        }).catch(error => {
                            this.unauthorizedError(error)
                        });
                    }
                });

            },
            unauthorizedError(err) {
                if (err.data.status === 401) {
                    return this.$router.push('/logout');
                }
                if (err.data.status === 400) {
                    this.errorMessage(err.data.message)
                }
                if(err.data.status === 500){
                    this.errorMessage('Failed! error')
                }
            },
            errorMessage(message){
                this.$swal({
                    icon: 'error',
                    text: message,
                    showCloseButton: true,
                    showConfirmButton: false,
                    timer: 3000
                });
            },
            successMessage(message){
                this.$swal({
                    icon: 'success',
                    text: message,
                    showCloseButton: false,
                    showConfirmButton: false,
                    timer: 3000
                });
            },
            toggleState(employer) {
                if (employer.item.status === "Block") {
                    this.api_status = 24;
                } else {
                    this.api_status = 23
                }
                this.loader = true;
                this.toggleStatus({id: employer.item.id, status: this.api_status}).then(res => {
                    if (res.status === 200) {
                        this.loader = false;
                        this.msg = "Status Updated successfully"
                        this.$swal({
                            icon: 'success',
                            toast: true,
                            text: this.msg,
                            showCloseButton: false,
                            showConfirmButton: false,
                            timer: 3000
                        })
                        if (this.api_status === 24) {
                            employer.item.status = "Unblock"
                        } else if (this.api_status === 23) {
                            employer.item.status = "Block"
                        }
                    }
                }).catch(() => {
                    this.loader = false;
                    this.$swal({
                        icon: 'error',
                        toast: true,
                        text: "Failed to save! Try again",
                        showCloseButton: false,
                        showConfirmButton: false,
                        timer: 3000
                    });
                })
            }
        },
        mounted() {
            this.getEmployers();
        },
        created() {
        },

    };
</script>

<style>
    .address{
        white-space: nowrap;
        width: 200px;
        overflow: hidden;
        text-overflow: ellipsis;
    }
</style>